import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MyTickets from "./MyTickets";
import EventItem from "./EventItem";
import ModalAlert from "./modal/ModalAlert";
import ModalOrder from "./modal/ModalOrder";
import ModalDisclaimer from "./modal/ModalDisclaimer";
import ModalImages from "./modal/ModalImages";
import { setLang } from "../../store/actions/userAction";
import { IsEmpty } from "../../common/helpers";
import { l10n } from "../../common/constants";
import { filter } from "lodash";
import { Spinner } from "react-bootstrap";
import { changeTimezone, getDateWithoutTimezone } from "../../common/helpers";
import ModalDynamicDisclaimer from "./modal/ModalDynamicDisclaimer";

let headers;
const kelasOptions = { 10: "Kelas 10", 11: "Kelas 11", 12: "Kelas 12" };
const registrationTypeOptions = {
    mandiri: "Diri Sendiri",
    wali: "Mendaftarkan Anak",
};
const genderOptions = { pria: "Pria", wanita: "Wanita" };
const positionCGOptions = {
    member: "Member",
    sponsor: "Sponsor",
    cgl: "CGL",
    coach: "Coach",
    tl: "TL",
};

class EventList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            welcomeShowModalDescription: null,
            agreement: false,
            alertShowModal: false,
            confirmationMessage: null,
            confirmationMode: 0,
            confirmationShowModal: false,
            deletedTicket: null,
            events: [],
            firstReject: false,
            firstShowModal: false,
            isLoading: true,
            isRegister: false,
            selectedEvent: {},
            selectedEventSub: {},
            showModal: false,
            ticket: 1,
            tickets: null,
            verifEmail: null,
            verifPhone: null,
            welcomeShowModal: false, // no need welcome modal
            welcomeModalClosed: false,
            redirectUrl: null,
            sizeMen: "M",
            sizeWomen: "M",
            sizeKids: "M",
            pickup: "SCC",
            type: "men",
            typeKids: "kids",
            kelas: "Kelas 6",
            registrationType: "Diri Sendiri",
            registrantName: "",
            registrantAge: "",
            gender: "men",
            phoneNumber: "",
            parentPhone: false,
            isCG: false,
            address: "",
            cgCode: "",
            positionCG: "Member",
            medicalCondition: "",
            catatan: "",
            imagesShowModal: false,
            ministry: "",
        };
    }

    async componentDidMount() {
        //* If there's no "user token" in local storage show unauthorized message
        if (!IsEmpty(localStorage.getItem("token"))) {
            //* Set default axios headers
            headers = {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            };

            //* Redux to set user lang
            this.props.setLang();
            await this.initData();
        } else {
            this.setState({ alertMessage: "Not Authorized" });
        }
        this.setState({ isLoading: false });
    }

    async initData() {
        let urlEvent = `${process.env.REACT_APP_API_URL}events`;
        let urlProfile = `${process.env.REACT_APP_API_URL}profile`;

        if (!IsEmpty(localStorage.getItem("exclusive"))) {
            urlEvent += `?exclusive=${localStorage.getItem("exclusive")}`;
            //urlProfile += `?exclusive=${localStorage.getItem('exclusive')}`; // just show all tickets for short solution
        }

        /*
    ! Currently our API return response status 404 if there's no event in progress
    * We need to give conditional statements at axios promise catch to make sure
    * the response 404 stands for network condition or there's no event data on it
    */

        await axios({
            method: "GET",
            url: urlEvent,
            headers,
        })
            .then((res) => {
                if (res.data.success) {
                    this.setState({ events: res.data.data });
                    const haveTnC = [
                        "special5",
                        "specialtnc1",
                        "specialtnc2",
                        "specialtnc3",
                        "specialtnc4",
                        "specialtnc5",
                        "aogbootcamp1",
                        "aogbootcamp1regional",
                        "aogbootcamp2",
                        "aogbootcamp2regional",
                        "gmsrunbalikpapan",
                        "christmascelebration",
                        "christmascelebrationforvip",
                    ];
                    if (
                        !IsEmpty(localStorage.getItem("exclusive")) &&
                        haveTnC.includes(localStorage.getItem("exclusive")) &&
                        !this.state.welcomeModalClosed
                    ) {
                        // ambil 1 sample
                        const data = res.data.data;
                        if (data.length > 0) {
                            const event = data[0];
                            this.setState({
                                welcomeShowModal: true,
                                welcomeShowModalDescription: event.TNC,
                            });
                        }
                    } else if (
                        !IsEmpty(localStorage.getItem("exclusive")) &&
                        (localStorage.getItem("exclusive") === "aogrun" ||
                            localStorage.getItem("exclusive") ===
                                "aogfindingmajor" ||
                            localStorage.getItem("exclusive") ===
                                "gmsrunmanado" ||
                            localStorage.getItem("exclusive") ===
                                "gmsrunbalikpapan" ||
                            localStorage.getItem("exclusive") ===
                                "aogbootcamp1" ||
                            localStorage.getItem("exclusive") ===
                                "aogbootcamp1regional" ||
                            localStorage.getItem("exclusive") ===
                                "aogbootcamp2" ||
                            localStorage.getItem("exclusive") ===
                                "aogbootcamp2regional") &&
                        !this.state.welcomeModalClosed
                    ) {
                        this.setState({ welcomeShowModal: true });
                    }
                } else {
                    this.setState({
                        alertShowModal: true,
                        alertMessage: "No Events in Progress",
                    });
                }
            })
            .catch((err) => {
                err.response.status === 404 &&
                err.response.data.message.includes("Data tidak ditemukan")
                    ? this.setState({ alertMessage: "No Events in Progress" })
                    : err.response.status === 500
                    ? this.setState({
                          alertMessage: `There was a problem with the server\n${err.response.status}`,
                      })
                    : this.setState({
                          alertMessage: `There was a problem with the network\n${err.response.status}`,
                      });
            });

        //* Fetching user ticket list, and account verification status
        await axios({
            method: "GET",
            url: urlProfile,
            headers,
        }).then((res) => {
            if (res.data.success) {
                const filteredTickets = res.data.data.tickets.filter(
                    (item) =>
                        item.bill_no === null ||
                        item.bill_no === "" ||
                        item.bill_no.includes("/EM/") ||
                        item.bill_no.includes("/GMS-RUN/")
                );
                this.setState({
                    tickets: filteredTickets,
                    verifEmail: res.data.data.verification.email,
                    verifPhone: res.data.data.verification.phone,
                });
            }
        });
    }

    bookTicket() {
        if (!this.state.isLoading) {
            if (
                !IsEmpty(this.state.ticket) &&
                this.state.ticket <= this.state.selectedEventSub.max_ticket
            ) {
                this.setState({ isLoading: true });
                let extras = null;
                if (this.state.selectedEvent.exclusive === "aogrun") {
                    let extrasArr = {};
                    if (
                        this.state.selectedEventSub.name
                            .toUpperCase()
                            .startsWith("KIDS")
                    ) {
                        extrasArr["type"] = this.state.typeKids;
                        extrasArr["size"] = this.state.sizeKids;
                    } else {
                        extrasArr["type"] = this.state.type;
                        if (this.state.type === "men") {
                            extrasArr["size"] = this.state.sizeMen;
                        } else if (this.state.type === "women") {
                            extrasArr["size"] = this.state.sizeWomen;
                        }
                    }
                    extrasArr["pickup"] = this.state.pickup;
                    extras = JSON.stringify(extrasArr);
                } else if (
                    this.state.selectedEvent.exclusive === "gmsrunmanado"
                ) {
                    let extrasArr = {};
                    extrasArr["size"] = this.state.sizeMen;
                    // extrasArr['pickup'] = this.state.pickup;
                    extras = extrasArr;
                } else if (
                    this.state.selectedEvent.exclusive === "gmsrunbalikpapan"
                ) {
                    let extrasArr = {};
                    if (
                        this.state.selectedEventSub.name
                            .toUpperCase()
                            .startsWith("ANAK")
                    ) {
                        extrasArr["type"] = this.state.typeKids;
                        extrasArr["size"] = this.state.sizeKids;
                    } else {
                        extrasArr["type"] = this.state.type;
                        if (this.state.type === "men") {
                            extrasArr["size"] = this.state.sizeMen;
                        } else if (this.state.type === "women") {
                            extrasArr["size"] = this.state.sizeWomen;
                        }
                    }
                    extras = JSON.stringify(extrasArr);
                } else if (
                    this.state.selectedEvent.exclusive === "aogfindingmajor"
                ) {
                    let extrasArr = {};
                    extrasArr["kelas"] = this.state.kelas;
                    extras = JSON.stringify(extrasArr);
                } else if (
                    this.state.selectedEvent.exclusive ===
                    "checklistinterpreter"
                ) {
                    let extrasArr = {};
                    extrasArr["interpreter"] = this.state.interpreter;
                    extras = JSON.stringify(extrasArr);
                } else if (
                    this.state.selectedEvent.exclusive === "aogbootcamp1" ||
                    this.state.selectedEvent.exclusive === "aogbootcamp1regional" ||
                    this.state.selectedEvent.exclusive === "aogbootcamp2" ||
                    this.state.selectedEvent.exclusive === "aogbootcamp2regional"
                ) {
                    let extrasArr = {};
                    extrasArr["jenisPendaftaran"] = this.state.registrationType;
                    extrasArr["name"] = this.state.registrantName;
                    extrasArr["isCG"] = this.state.isCG;
                    extrasArr["age"] = this.state.registrantAge;
                    extrasArr["gender"] = this.state.gender;
                    extrasArr["phoneNumber"] = this.state.phoneNumber;
                    extrasArr["medicalCondition"] = this.state.medicalCondition;
                    extras = JSON.stringify(extrasArr);
                } else if (
                    this.state.selectedEvent.exclusive ===
                    "christmascelebrationforvip"
                ) {
                    let extrasArr = {};
                    extrasArr["forvip"] = 1;
                    extrasArr["catatan"] = this.state.catatan;
                    extras = JSON.stringify(extrasArr);
                } else if (/^vm\d$/.test(this.state.selectedEvent.exclusive)) {
                    let extrasArr = {};
                    extrasArr["ministry"] = this.state.ministry;
                    extras = JSON.stringify(extrasArr);
                }
                console.log(extras);
                axios({
                    method: "POST",
                    url: `${process.env.REACT_APP_API_URL}register`,
                    headers,
                    data: {
                        church_event_id: this.state.selectedEventSub.id,
                        quantity: this.state.ticket,
                        extras: extras,
                    },
                })
                    .then((res) => {
                        this.setState({ isLoading: false });
                        if (res.data.success) {
                            if (res.data.data.redirect_url) {
                                this.setState({
                                    redirectUrl: res.data.data.redirect_url,
                                });
                            }
                            this.setState({ isRegister: true });
                        } else {
                            res.data.errors.forEach(function (part, index) {
                                this.setState({
                                    alertShowModal: true,
                                    alertMessage: part,
                                });
                            });
                        }
                    })
                    .catch((error) => {
                        this.setState({ isLoading: false });
                        this.setState({
                            alertShowModal: true,
                            alertMessage: error.response.data.message,
                        });
                        this.refetchEvent();
                    });
            } else {
                this.setState({
                    alertShowModal: true,
                    alertMessage: l10n["selectTicket"][this.props.language],
                });
            }
        }
    }

    //* Handle selecting Event, user must already verify email & phone on GMS Church Apps
    selectEvent = (event, eventSub) => {
        if (
            (this.state.verifEmail && this.state.verifPhone) ||
            event.exclusive === "gmsrunbalikpapan"
        ) {
            this.setState({
                selectedEvent: event,
                selectedEventSub: eventSub,
                showModal: true,
                ticket: 1,
            });
        } else {
            this.setState({
                alertShowModal: true,
                alertMessage: l10n["verifyAccount"][this.props.language],
            });
        }
    };

    //* Re-fetch number of ticket left
    refetchEvent = async () => {
        const selectedEvent = this.state.selectedEvent;
        const selectedEventSubId = this.state.selectedEventSub.id;

        await this.initData();
        const updatedEvent = filter(this.state.events, {
            name: selectedEvent.name,
        });
        const updatedEventSub = updatedEvent[0].sub_event.filter(
            (e) => e.id === selectedEventSubId
        );
        this.setState({
            selectedEvent: updatedEvent[0],
            selectedEventSub: updatedEventSub[0],
            ticket: 1,
        });
    };

    onCheckAgreement = (e) => {
        let agree = this.state.agreement;
        this.setState({ agreement: !agree });
    };

    onSubmitTicket = (e) => {
        e.preventDefault();
        let ticketsService = this.state.tickets.filter(
            (ticket) => ticket.sub_event.main_event.event_type !== "event"
        );
        if (
            this.state.tickets &&
            !IsEmpty(ticketsService) &&
            this.state.selectedEvent.event_type !== "event"
        ) {
            this.setState({
                confirmationShowModal: true,
                confirmationMode: 1,
                confirmationMessage:
                    l10n["cancelTicketForOrder"][this.props.language],
            });
        } else {
            this.bookTicket();
        }
    };

    onChangeTicket = (e) =>
        this.setState({ [e.target.name]: Number(e.target.value) });

    onChangeSelect = (e) => this.setState({ [e.target.name]: e.target.value });

    onChangeKelas = (kelas) => this.setState({ kelas });

    onChangeRegistrationType = (registrationType) =>
        this.setState({ registrationType });

    onChangeRegistrantName = (registrantName) =>
        this.setState({ registrantName });

    onChangeRegistrantAge = (registrantAge) =>
        this.setState({ registrantAge });

    onChangeGender = (gender) => this.setState({ gender });

    onChangePhoneNumber = (phoneNumber) => this.setState({ phoneNumber });

    onChangeMinistry = (ministry) => this.setState({ ministry });

    onChangeParentPhone = (parentPhone) => this.setState({ parentPhone });

    onChangeIsCG = (isCG) => this.setState({ isCG });

    onChangeAddress = (address) => this.setState({ address });

    onChangeCGCode = (cgCode) => this.setState({ cgCode });

    onChangePositionCG = (positionCG) => this.setState({ positionCG });

    onChangeMedicalCondition = (medicalCondition) =>
        this.setState({ medicalCondition });

    onChangeCatatan = (catatan) => this.setState({ catatan });

    onClickTicketButton = (e) =>
        this.setState({ ticket: Number(e.target.value) });

    onChangeTicketQuantity = (qty) => this.setState({ ticket: Number(qty) });

    showModalChartSize = (e) => {
        this.setState({ imagesShowModal: true });
    };
    imagesHandleClose = (e) => {
        this.setState({ imagesShowModal: false });
    };

    deleteTicket = (event) => {
        this.setState({
            confirmationShowModal: true,
            deletedTicket: event,
            confirmationMode: 0,
            confirmationMessage:
                l10n["cancelSelectedTicket"][this.props.language],
        });
    };

    processPaymentTicket = (event) => {
        if (!this.state.isLoading) {
            if (!IsEmpty(event) && event.bill_no) {
                this.setState({ isLoading: true });
                axios({
                    method: "GET",
                    url: `${process.env.REACT_APP_API_URL}payment/transaction/detail`,
                    headers,
                })
                    .then((res) => {
                        this.setState({ isLoading: false });
                        if (res.data && res.data.data) {
                            let selectedTicket = res.data.data.find(
                                (o) => o.bill_no === event.bill_no
                            );
                            window.location.href = selectedTicket.payment_url;
                        }
                    })
                    .catch((error) => {
                        this.setState({ isLoading: false });
                        this.setState({
                            alertShowModal: true,
                            alertMessage: error.response.data.message,
                        });
                        this.refetchEvent();
                    });
            }
        }
    };

    confirmationSubmit = (e) => {
        if (this.state.confirmationMode === 0) {
            // del ticket
            axios({
                method: "POST",
                url: `${process.env.REACT_APP_API_URL}register/cancel`,
                headers,
                data: {
                    booking_code: this.state.deletedTicket.code,
                },
            })
                .then((res) => {
                    this.setState({
                        confirmationShowModal: false,
                        tickets: [
                            ...this.state.tickets.filter(
                                (ticket) =>
                                    ticket.code !==
                                    this.state.deletedTicket.code
                            ),
                        ],
                    });
                })
                .catch((error) => {
                    this.setState({
                        alertShowModal: true,
                        alertMessage: error.response.data.message,
                    });
                });
        } else if (this.state.confirmationMode === 1) {
            this.bookTicket();
        }
    };

    render() {
        const handleClose = () => this.setState({ showModal: false });

        const firstHandleClose = () => {
            if (this.state.agreement) {
                this.setState({ firstShowModal: false });
            } else {
                this.setState({
                    alertShowModal: true,
                    alertMessage: l10n["needAgreement"][this.props.language],
                });
            }
        };

        const welcomeHandleClose = () => {
            if (
                !IsEmpty(localStorage.getItem("exclusive")) &&
                (localStorage.getItem("exclusive") === "aogrun" ||
                    localStorage.getItem("exclusive") === "aogfindingmajor" ||
                    localStorage.getItem("exclusive") === "gmsrunmanado" ||
                    localStorage.getItem("exclusive") === "gmsrunbalikpapan" ||
                    localStorage.getItem("exclusive") === "christmas" ||
                    localStorage.getItem("exclusive") === "welcomepage" ||
                    localStorage.getItem("exclusive") === "welcomepage2" ||
                    localStorage.getItem("exclusive") === "aogbootcamp1" ||
                    localStorage.getItem("exclusive") === "aogbootcamp1regional" ||
                    localStorage.getItem("exclusive") === "aogbootcamp2" ||
                    localStorage.getItem("exclusive") === "aogbootcamp2regional")
            ) {
                this.setState({
                    welcomeShowModal: false,
                    welcomeModalClosed: true,
                });
            } else {
                this.setState({
                    firstShowModal: true,
                    welcomeShowModal: false,
                });
            }
        };

        const alertHandleClose = () => {
            this.setState({ alertShowModal: false, alertMessage: "" });
        };

        const confirmationHandleClose = () => {
            this.setState({ confirmationShowModal: false });
        };

        const firstHandleReject = () => this.setState({ firstReject: true });

        if (this.state.firstReject) {
            window.location.href =
                "https://accounts.gms.church/:register_cancel";
            return null;
        }

        //* Redirect to ticket pages on GMS Church App
        if (this.state.isRegister) {
            if (this.state.redirectUrl) {
                window.location.href = this.state.redirectUrl;
            } else {
                window.location.href =
                    "https://accounts.gms.church/:register_success?event_code=" +
                    this.state.selectedEvent.code;
            }
            return null;
        }

        //* Create button for number of tickets, used in modal order
        /*
         * +---+ +---+ +---+
         * | 1 | | 2 | | 3 |
         * +---+ +---+ +---+
         */
        const TicketButton = () => {
            const maxTicketOrder = this.state.selectedEventSub.max_ticket;
            const totalTicketLeft = this.state.selectedEventSub.quantity;
            return (
                <span>
                    {Array.from(Array(maxTicketOrder), (e, i) => {
                        return (
                            <input
                                disabled={i + 1 > totalTicketLeft}
                                type="button"
                                value={i + 1}
                                key={`ticket-${i + 1}`}
                                className={`btn ${
                                    this.state.ticket === i + 1 &&
                                    totalTicketLeft !== 0
                                        ? "btn-primary"
                                        : "btn-secondary"
                                }`}
                                style={ticketButtonStyle}
                                onClick={this.onClickTicketButton}
                            />
                        );
                    })}
                </span>
            );
        };

        const RoomButton = () => {
            const roomList = [];
            const selectedEventSub = this.state.selectedEventSub;
            const selectedEvent = filter(this.state.selectedEvent.sub_event, {
                start_date: selectedEventSub.start_date,
            });
            const subEventUnique = filter(
                this.state.selectedEvent.uniqueDates,
                { date: getDateWithoutTimezone(selectedEventSub.start_date) }
            );
            /*
             * Note: subEventUnique structure
             * [
             *  date : "Sunday, 23 May 2021",
             *  uniqueRooms: ["Mainhall","Overflow"]
             * ]
             */

            /*
             *  Check if current sub_event's room is on the room list,
             *  to handle case if in one event, each sub_event has different available room,
             *  because uniqueRooms defined on each events not sub events
             *  ex :
             *
             *  Service 1
             *  +----------+----------+
             *  | Mainhall | Overflow |
             *  +----------+----------+
             *
             *  Service 2
             *  +---------------------+
             *  |       Mainhall      |
             *  +---------------------+
             */

            subEventUnique[0].uniqueRooms.forEach((room) => {
                if (
                    !IsEmpty(
                        selectedEvent[
                            selectedEvent.findIndex(
                                (event) => event.room.name === room
                            )
                        ]
                    )
                ) {
                    roomList.push(room);
                }
            });

            return (
                <div
                    className="btn-group btn-group-toggle btn-block"
                    onChange={(e) => {
                        this.selectEvent(
                            this.state.selectedEvent,
                            selectedEvent[
                                selectedEvent.findIndex(
                                    (event) =>
                                        event.room.name === e.target.value
                                )
                            ]
                        );
                    }}
                >
                    {roomList.sort().map((room, i) => {
                        return (
                            <label
                                className={`btn ${
                                    selectedEventSub.room.name === room
                                        ? "btn-outline-primary active"
                                        : "btn-outline-dark"
                                }`}
                                key={i}
                            >
                                <input type="radio" value={room} />
                                {room}
                            </label>
                        );
                    })}
                </div>
            );
        };

        //* Add variable uniqueDates for each Events
        this.state.events.forEach((part, index) => {
            part.uniqueDates = [];
            part.sub_event.forEach((_part, _index) => {
                _part.start_date = changeTimezone(_part.start_date);
                _part.end_date = changeTimezone(_part.end_date);
                _part.service_date = getDateWithoutTimezone(_part.start_date);
                if (
                    part.uniqueDates
                        .map((e) => {
                            return e.date;
                        })
                        .indexOf(_part.service_date) === -1
                ) {
                    let uniqueDate = [];
                    uniqueDate.date = _part.service_date;
                    part.uniqueDates.push(uniqueDate);
                }
            });

            //* Add variable uniqueRoom for each uniqueDates
            part.uniqueDates.forEach((_part, _index) => {
                _part.uniqueRooms = [];
                part.sub_event.forEach((__part, __index) => {
                    if (
                        _part.date === __part.service_date &&
                        _part.uniqueRooms.indexOf(__part.room.name) === -1
                    ) {
                        _part.uniqueRooms.push(__part.room.name);
                    }
                });
            });
        });
        return (
            <>
                {this.state.isLoading ? (
                    <div
                        className="mt-4"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Spinner animation="border" />
                    </div>
                ) : null}
                {!this.state.isLoading && !IsEmpty(this.state.events) ? (
                    <>
                        <div className="container-fluid no-padding">
                            <div
                                className="row"
                                style={{ justifyContent: "center" }}
                            >
                                <div className="col-sm-8 col-12">
                                    <div style={churchHeaderStyle}>
                                        <FontAwesomeIcon
                                            icon={faMapMarkerAlt}
                                            style={{
                                                color: "#bd3026",
                                                marginRight: "7px",
                                                marginBottom: "2px",
                                            }}
                                        />{" "}
                                        {localStorage.getItem("church_name")}
                                    </div>
                                </div>
                            </div>

                            {this.state.events.map((event, i) => (
                                <React.Fragment key={`event-${i}`}>
                                    {/* Divider Line */}
                                    {i !== 0 ? (
                                        <hr
                                            style={{
                                                margin: "2px",
                                                marginBottom: "20px",
                                            }}
                                        />
                                    ) : null}

                                    <EventItem
                                        key={event.code}
                                        event={event}
                                        selectEvent={this.selectEvent}
                                    />

                                    {i === this.state.events.length - 1 ? (
                                        <div style={{ marginBottom: "5vh" }} />
                                    ) : null}
                                </React.Fragment>
                            ))}
                        </div>

                        <ModalOrder
                            showModal={this.state.showModal}
                            handleClose={handleClose}
                            type={this.state.type}
                            typeKids={this.state.typeKids}
                            sizeMen={this.state.sizeMen}
                            sizeWomen={this.state.sizeWomen}
                            sizeKids={this.state.sizeKids}
                            pickup={this.state.pickup}
                            ticket={this.state.ticket}
                            maxTicketOrder={
                                this.state.selectedEventSub.max_ticket
                            }
                            onChangeTicketQuantity={this.onChangeTicketQuantity}
                            onChangeTicket={this.onChangeTicket}
                            onChangeSelect={this.onChangeSelect}
                            selectedEvent={this.state.selectedEvent}
                            onChangeMinistry={this.onChangeMinistry}
                            selectedEventSub={this.state.selectedEventSub}
                            ticketButton={TicketButton}
                            roomButton={RoomButton}
                            onSubmitTicket={this.onSubmitTicket}
                            onChangeEventSub={this.onChangeEventSub}
                            showModalChartSize={this.showModalChartSize}
                            kelasOptions={kelasOptions}
                            registrationTypeOptions={registrationTypeOptions}
                            genderOptions={genderOptions}
                            positionCGOptions={positionCGOptions}
                            onChangeKelas={this.onChangeKelas}
                            onChangeRegistrationType={
                                this.onChangeRegistrationType
                            }
                            registrantName={this.state.registrantName}
                            registrantAge={this.state.registrantAge}
                            phoneNumber={this.state.phoneNumber}
                            parentPhone={this.state.parentPhone}
                            ministry={this.state.ministry}
                            isCG={this.state.isCG}
                            address={this.state.address}
                            cgCode={this.state.cgCode}
                            medicalCondition={this.state.medicalCondition}
                            onChangeMedicalCondition={
                                this.onChangeMedicalCondition
                            }
                            onChangeCGCode={this.onChangeCGCode}
                            onChangeAddress={this.onChangeAddress}
                            onChangeIsCG={this.onChangeIsCG}
                            onChangeParentPhone={this.onChangeParentPhone}
                            onChangePhoneNumber={this.onChangePhoneNumber}
                            onChangeRegistrantName={this.onChangeRegistrantName}
                            onChangeRegistrantAge={this.onChangeRegistrantAge}
                            onChangeGender={this.onChangeGender}
                            onChangePositionCG={this.onChangePositionCG}
                            catatan={this.state.catatan}
                            onChangeCatatan={this.onChangeCatatan}
                        />
                        {!this.state.welcomeShowModalDescription && (
                            <ModalDisclaimer
                                agreement={this.state.agreement}
                                firstShowModal={this.state.firstShowModal}
                                firstHandleReject={firstHandleReject}
                                firstHandleClose={firstHandleClose}
                                welcomeShowModal={this.state.welcomeShowModal}
                                welcomeHandleClose={welcomeHandleClose}
                                onCheckAgreement={this.onCheckAgreement}
                            />
                        )}
                        {this.state.welcomeShowModalDescription && (
                            <ModalDynamicDisclaimer
                                agreement={this.state.agreement}
                                firstShowModal={this.state.firstShowModal}
                                firstHandleReject={welcomeHandleClose}
                                firstHandleClose={welcomeHandleClose}
                                welcomeShowModal={this.state.welcomeShowModal}
                                welcomeShowModalDescription={
                                    this.state.welcomeShowModalDescription
                                }
                                welcomeHandleClose={welcomeHandleClose}
                                onCheckAgreement={this.onCheckAgreement}
                            />
                        )}

                        <ModalAlert
                            alertShowModal={this.state.alertShowModal}
                            alertHandleClose={alertHandleClose}
                            alertMessage={this.state.alertMessage}
                            confirmationShowModal={
                                this.state.confirmationShowModal
                            }
                            confirmationHandleClose={confirmationHandleClose}
                            confirmationMessage={this.state.confirmationMessage}
                            confirmationSubmit={this.confirmationSubmit}
                        />

                        <ModalImages
                            imagesShowModal={this.state.imagesShowModal}
                            imagesHandleClose={this.imagesHandleClose}
                            selectedEventSubName={
                                this.state.selectedEventSub.name
                            }
                        />
                    </>
                ) : (
                    <div style={messageStyle}>
                        <h6>{this.state.alertMessage}</h6>
                    </div>
                )}
                {/* Ordered Ticket */}
                {!this.state.isLoading &&
                this.state.tickets &&
                !IsEmpty(this.state.tickets) ? (
                    <MyTickets
                        tickets={this.state.tickets}
                        deleteTicket={this.deleteTicket}
                        processPaymentTicket={this.processPaymentTicket}
                    />
                ) : null}
            </>
        );
    }
}

// Styles
const churchHeaderStyle = {
    display: "none",
    alignItems: "center",
    margin: "10px 10px 15px 15px",
    fontSize: "14px",
    fontWeight: "400",
    paddingBottom: "6px",
    float: "right",
};

const ticketButtonStyle = {
    padding: "10px 18px",
    marginRight: "12px",
};

const messageStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "2vh",
};

// Redux
const mapStateToProps = (state) => ({
    language: state.userReducer.language,
});

const mapDispatchToProps = (dispatch) => ({
    setLang: (userChurchId) => setLang(dispatch, userChurchId),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventList);
