import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
    getDateWithoutTimezone,
    getTimeWithoutTimezone,
} from "../../../common/helpers";
import { l10n } from "../../../common/constants";
import { Button, InputGroup, Modal, Form } from "react-bootstrap";
import { useMinistries } from "../../../lib/vmapi";

export default function ModalOrder(props) {
    const { language } = useSelector((state) => ({
        language: state.userReducer.language,
    }));

    const [minQtyViewSeatsLeft, setMinQtyViewSeatsLeft] = useState(100);
    const [agreeOverflow, setAgreeOverflow] = useState(false);
    const { kelasOptions, onChangeKelas } = props;
    const [kelas, setKelas] = useState("");
    const [kelasOther, setKelasOther] = useState("");
    const txtOther = useRef(null);
    const exclusive = localStorage.getItem("exclusive");

    useEffect(() => {
        if (props.selectedEventSub.hasOwnProperty("room")) {
            if (
                props.selectedEventSub.room.hasOwnProperty("estimated_quantity")
            ) {
                const { estimated_quantity } = props.selectedEventSub.room;
                setMinQtyViewSeatsLeft(estimated_quantity * (20 / 100));
            }
        }
    }, [minQtyViewSeatsLeft, props.selectedEventSub]);

    const otherChecked = kelas === "Other";
    useEffect(() => {
        if (kelas === "Other") {
            txtOther.current.focus();
            onChangeKelas(kelasOther);
            return;
        }
        onChangeKelas(kelas);
    }, [kelas, kelasOther, onChangeKelas]);

    const aogFindingMajorCheck =
        exclusive === "aogfindingmajor" &&
        (kelas === "" || (kelas === "Other" && kelasOther === ""));

    // AOG BootCamp
    const { registrationTypeOptions, onChangeRegistrationType } = props;
    const [registrationType, setRegistrationType] = useState("mandiri");
    useEffect(() => {
        onChangeRegistrationType(registrationType);
    }, [registrationType, onChangeRegistrationType]);

    const { registrantName, onChangeRegistrantName } = props;
    const { registrantAge, onChangeRegistrantAge } = props;
    const { genderOptions, onChangeGender } = props;
    const [gender, setGender] = useState("pria");
    useEffect(() => {
        onChangeGender(gender);
    }, [gender, onChangeGender]);

    const { phoneNumber, onChangePhoneNumber } = props;

    

    const { isCG, onChangeIsCG } = props;

    const { ministry, onChangeMinistry } = props;

    const { medicalCondition, onChangeMedicalCondition } = props;
    const { catatan, onChangeCatatan } = props;

    const [ticketQty, setTicketQty] = useState("1");
    const onInputTicketBlur = () => {
        const maxTicketOrder = Number(props.maxTicketOrder);
        let ticketQuantity = Number(ticketQty);
        if (ticketQuantity > maxTicketOrder) ticketQuantity = maxTicketOrder;
        else if (ticketQuantity < 1) ticketQuantity = 1;
        setTicketQty(ticketQuantity);
        props.onChangeTicketQuantity(ticketQuantity);
    };

    const increaseTicketQty = () => {
        const qty = Number(ticketQty);
        if (qty < Number(props.maxTicketOrder)) {
            setTicketQty(qty + 1);
            props.onChangeTicketQuantity(qty + 1);
        }
    };

    const decreaseTicketQty = () => {
        const qty = Number(ticketQty);
        if (qty > 1) {
            setTicketQty(qty - 1);
            props.onChangeTicketQuantity(qty - 1);
        }
    };

    const aogBootcampCheck =
        (exclusive === "aogbootcamp1" || exclusive === "aogbootcamp1regional" || exclusive === "aogbootcamp2" || exclusive === "aogbootcamp2regional") &&
        (phoneNumber === "" || medicalCondition === "" || (registrationType === "wali" && (registrantName === "" || registrantAge === "")));

    const christmasVipCheck =
        exclusive === "christmascelebrationforvip" && catatan === "";
    const vmCheck = /^vm\d$/.test(exclusive) && !ministry;
    const isVMEvent = /^vm\d$/.test(props.selectedEvent.exclusive);
    const {
        data: ministries,
        isLoading: ministriesLoading,
        isError: ministriesError,
    } = useMinistries(isVMEvent);

    const renderVMForm = () => {
        return (
            <div className="form-group">
                <div style={{ marginTop: "8px" }}>
                    {ministriesLoading ? (
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    ) : ministriesError ? (
                        <div>
                            <div>Failed to load ministries</div>
                            <button
                                className="btn btn-link p-0"
                                onClick={() => window.location.reload()}
                            >
                                Click to refresh
                            </button>
                        </div>
                    ) : ministries !== null ? (
                        <select
                            style={selectStyle}
                            value={props.ministry}
                            name="ministry"
                            id="ministry"
                            onChange={(e) => onChangeMinistry(e.target.value)}
                        >
                            <option value="">-- Select Ministry --</option>
                            {ministries.map((ministry) => (
                                <option
                                    key={`ministry-${ministry.id}`}
                                    value={ministry.id}
                                >
                                    {ministry.name}
                                </option>
                            ))}
                        </select>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        );
    };

    //return
    return (
        <>
            <Modal
                className="modal-order-ticket"
                show={props.showModal}
                onHide={props.handleClose}
            >
                <form
                    action="./status"
                    className="container"
                    onSubmit={props.onSubmitTicket}
                >
                    <Modal.Header closeButton>
                        <Modal.Title style={modalTitleStyle}>
                            {props.selectedEvent.name}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {/* Quantity Left */}
                        {props.selectedEventSub.quantity <=
                        minQtyViewSeatsLeft ? (
                            <div className="form-group">
                                <span style={modalLabelStyle}>
                                    {l10n["quota"][language]}
                                </span>
                                <span style={modalValueStyle}>
                                    {props.selectedEventSub.quantity > 0
                                        ? props.selectedEventSub.quantity
                                        : 0}{" "}
                                    {l10n["remaining"][language]}
                                </span>
                            </div>
                        ) : null}

                        <div style={infoStyle}>
                            {getDateWithoutTimezone(
                                props.selectedEventSub.start_date
                            )}{" "}
                            &#x2022;{" "}
                            {getTimeWithoutTimezone(
                                props.selectedEventSub.start_date
                            )}
                        </div>
                        <div style={titleStyle}>
                            {props.selectedEventSub.name}
                        </div>

                        {/* Service Room Button Selection */}
                        <div className="form-group">
                            <span style={modalLabelStyle}>
                                {l10n["room"][language]}
                            </span>
                            <span style={modalValueStyle}>
                                {/* {props.selectedEventSub.room ? (props.selectedEventSub.room.name) : null} */}
                            </span>
                            <props.roomButton />
                        </div>

                        {/* Form for GMS Run */}
                        {props.selectedEvent.exclusive === "aogrun" ? (
                            <>
                                <div className="form-group">
                                    <span style={modalLabelStyle}>
                                        Select Jersey Size.{" "}
                                        <span
                                            onClick={props.showModalChartSize}
                                            style={buttonChartSizeStyle}
                                        >
                                            see size chart
                                        </span>
                                    </span>

                                    {props.selectedEventSub.name
                                        .toUpperCase()
                                        .startsWith("KIDS") ? (
                                        <>
                                            <div style={{ marginTop: "8px" }}>
                                                <select
                                                    style={selectStyle}
                                                    value={props.typeKids}
                                                    name="typeKids"
                                                    id="typeKids"
                                                    onChange={
                                                        props.onChangeSelect
                                                    }
                                                >
                                                    <option value="kids">
                                                        Kids
                                                    </option>
                                                </select>
                                            </div>
                                            <div>
                                                <select
                                                    style={selectStyle}
                                                    value={props.sizeKids}
                                                    name="sizeKids"
                                                    id="sizeKids"
                                                    onChange={
                                                        props.onChangeSelect
                                                    }
                                                >
                                                    <option value="S">S</option>
                                                    <option value="M">M</option>
                                                    <option value="L">L</option>
                                                    <option value="XL">
                                                        XL
                                                    </option>
                                                </select>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div style={{ marginTop: "8px" }}>
                                                <select
                                                    style={selectStyle}
                                                    value={props.type}
                                                    name="type"
                                                    id="type"
                                                    onChange={
                                                        props.onChangeSelect
                                                    }
                                                >
                                                    <option value="men">
                                                        Men
                                                    </option>
                                                    <option value="women">
                                                        Women
                                                    </option>
                                                </select>
                                            </div>
                                            {props.type === "men" ? (
                                                <div>
                                                    <select
                                                        style={selectStyle}
                                                        value={props.sizeMen}
                                                        name="sizeMen"
                                                        id="sizeMen"
                                                        onChange={
                                                            props.onChangeSelect
                                                        }
                                                    >
                                                        <option value="S">
                                                            S
                                                        </option>
                                                        <option value="M">
                                                            M
                                                        </option>
                                                        <option value="L">
                                                            L
                                                        </option>
                                                        <option value="XL">
                                                            XL
                                                        </option>
                                                        <option value="2XL">
                                                            2XL
                                                        </option>
                                                        <option value="3XL">
                                                            3XL
                                                        </option>
                                                    </select>
                                                </div>
                                            ) : null}
                                            {props.type === "women" ? (
                                                <div>
                                                    <select
                                                        style={selectStyle}
                                                        value={props.sizeWomen}
                                                        name="sizeWomen"
                                                        id="sizeWomen"
                                                        onChange={
                                                            props.onChangeSelect
                                                        }
                                                    >
                                                        <option value="S">
                                                            S
                                                        </option>
                                                        <option value="M">
                                                            M
                                                        </option>
                                                        <option value="L">
                                                            L
                                                        </option>
                                                        <option value="XL">
                                                            XL
                                                        </option>
                                                        <option value="2XL">
                                                            2XL
                                                        </option>
                                                    </select>
                                                </div>
                                            ) : null}
                                        </>
                                    )}
                                </div>

                                <div className="form-group">
                                    <span style={modalLabelStyle}>
                                        Racepack Pickup
                                    </span>

                                    <div>
                                        <select
                                            style={selectStyle}
                                            value={props.pickup}
                                            name="pickup"
                                            id="pickup"
                                            onChange={props.onChangeSelect}
                                        >
                                            <option value="SCC">
                                                SCC 11-12 Feb (Barat)
                                            </option>
                                            <option value="PCM">
                                                PCM 12 Feb (Timur)
                                            </option>
                                            <option value="MCC">
                                                MCC 12 Feb (Selatan)
                                            </option>
                                            <option value="SCCWD">
                                                SCC 14-16 Feb (Barat Weekdays)
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </>
                        ) : null}

                        {props.selectedEvent.exclusive === "gmsrunmanado" ? (
                            <>
                                <div className="form-group">
                                    <span style={modalLabelStyle}>
                                        Select Jersey Size.{" "}
                                        <span
                                            onClick={props.showModalChartSize}
                                            style={buttonChartSizeStyle}
                                        >
                                            see size chart
                                        </span>
                                    </span>

                                    {false ? (
                                        <>
                                            <div style={{ marginTop: "8px" }}>
                                                <select
                                                    style={selectStyle}
                                                    value={props.typeKids}
                                                    name="typeKids"
                                                    id="typeKids"
                                                    onChange={
                                                        props.onChangeSelect
                                                    }
                                                >
                                                    <option value="kids">
                                                        Kids
                                                    </option>
                                                </select>
                                            </div>
                                            <div>
                                                <select
                                                    style={selectStyle}
                                                    value={props.sizeKids}
                                                    name="sizeKids"
                                                    id="sizeKids"
                                                    onChange={
                                                        props.onChangeSelect
                                                    }
                                                >
                                                    <option value="S">S</option>
                                                    <option value="M">M</option>
                                                    <option value="L">L</option>
                                                    <option value="XL">
                                                        XL
                                                    </option>
                                                </select>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            {/* <div style={{ marginTop: '8px' }}><select style={selectStyle} value={props.type} name="type" id="type"
                                onChange={props.onChangeSelect}>
                                <option value="men">Men</option>
                                <option value="women">Women</option>
                            </select></div> */}
                                            {props.type === "men" ? (
                                                <div>
                                                    <select
                                                        style={selectStyle}
                                                        value={props.sizeMen}
                                                        name="sizeMen"
                                                        id="sizeMen"
                                                        onChange={
                                                            props.onChangeSelect
                                                        }
                                                    >
                                                        <option value="XS">
                                                            XS
                                                        </option>
                                                        <option value="S">
                                                            S
                                                        </option>
                                                        <option value="M">
                                                            M
                                                        </option>
                                                        <option value="L">
                                                            L
                                                        </option>
                                                        <option value="XL">
                                                            XL
                                                        </option>
                                                        <option value="2XL">
                                                            2XL
                                                        </option>
                                                        <option value="3XL">
                                                            3XL
                                                        </option>
                                                    </select>
                                                </div>
                                            ) : null}
                                        </>
                                    )}
                                </div>

                                <div className="form-group">
                                    <span style={modalLabelStyle}>
                                        Racepack Pickup
                                    </span>
                                    <span style={modalLabelStyle}>
                                        Racekit di GMS Manado, Jl. Wolter
                                        Monginsidi No.1, Kompleks Bahumall,
                                        Samping Apartemen Lagoon/Hotel Best
                                        Western
                                    </span>
                                    <span style={modalLabelStyle}>
                                        Penukaran Racekit tanggal 1-3 November
                                        2023
                                    </span>
                                    <span style={modalLabelStyle}>
                                        Jam penukaran mulai 10.00 sampai 20.00
                                        WITA
                                    </span>
                                    {/* <div><select style={selectStyle} value={props.pickup} name="pickup" id="pickup"
                                onChange={props.onChangeSelect}>
                            <option value="SCC">SCC 11-12 Feb (Barat)</option>
                            <option value="PCM">PCM 12 Feb (Timur)</option>
                            <option value="MCC">MCC 12 Feb (Selatan)</option>
                            <option value="SCCWD">SCC 14-16 Feb (Barat Weekdays)</option>
                            </select></div> */}
                                </div>
                            </>
                        ) : null}

                        {props.selectedEvent.exclusive &&
                            isVMEvent &&
                            renderVMForm()}

                        {props.selectedEvent.exclusive ===
                        "gmsrunbalikpapan" ? (
                            <>
                                <div className="form-group">
                                    <span style={modalLabelStyle}>
                                        Select Jersey Size.{" "}
                                        <span
                                            onClick={props.showModalChartSize}
                                            style={buttonChartSizeStyle}
                                        >
                                            Click here for the size chart
                                        </span>
                                    </span>

                                    {props.selectedEventSub.name
                                        .toUpperCase()
                                        .startsWith("ANAK") ? (
                                        <>
                                            <div style={{ marginTop: "8px" }}>
                                                <select
                                                    style={selectStyle}
                                                    value={props.typeKids}
                                                    name="typeKids"
                                                    id="typeKids"
                                                    onChange={
                                                        props.onChangeSelect
                                                    }
                                                >
                                                    <option value="kids">
                                                        Kids
                                                    </option>
                                                </select>
                                            </div>
                                            <div>
                                                <select
                                                    style={selectStyle}
                                                    value={props.sizeKids}
                                                    name="sizeKids"
                                                    id="sizeKids"
                                                    onChange={
                                                        props.onChangeSelect
                                                    }
                                                >
                                                    <option value="XS">
                                                        XS
                                                    </option>
                                                    <option value="S">S</option>
                                                    <option value="M">M</option>
                                                    <option value="L">L</option>
                                                    <option value="XL">
                                                        XL
                                                    </option>
                                                    <option value="XXL">
                                                        XXL
                                                    </option>
                                                </select>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div style={{ marginTop: "8px" }}>
                                                <select
                                                    style={selectStyle}
                                                    value={props.type}
                                                    name="type"
                                                    id="type"
                                                    onChange={
                                                        props.onChangeSelect
                                                    }
                                                >
                                                    <option value="men">
                                                        Men
                                                    </option>
                                                    <option value="women">
                                                        Women
                                                    </option>
                                                </select>
                                            </div>

                                            {props.type === "men" ? (
                                                <div>
                                                    <select
                                                        style={selectStyle}
                                                        value={props.sizeMen}
                                                        name="sizeMen"
                                                        id="sizeMen"
                                                        onChange={
                                                            props.onChangeSelect
                                                        }
                                                    >
                                                        <option value="XS">
                                                            XS
                                                        </option>
                                                        <option value="S">
                                                            S
                                                        </option>
                                                        <option value="M">
                                                            M
                                                        </option>
                                                        <option value="L">
                                                            L
                                                        </option>
                                                        <option value="XL">
                                                            XL
                                                        </option>
                                                        <option value="XXL">
                                                            XXL
                                                        </option>
                                                        <option value="XXXL">
                                                            XXXL
                                                        </option>
                                                        <option value="XXXXL">
                                                            XXXXL
                                                        </option>
                                                    </select>
                                                </div>
                                            ) : null}

                                            {props.type === "women" ? (
                                                <div>
                                                    <select
                                                        style={selectStyle}
                                                        value={props.sizeWomen}
                                                        name="sizeWomen"
                                                        id="sizeWomen"
                                                        onChange={
                                                            props.onChangeSelect
                                                        }
                                                    >
                                                        <option value="XS">
                                                            XS
                                                        </option>
                                                        <option value="S">
                                                            S
                                                        </option>
                                                        <option value="M">
                                                            M
                                                        </option>
                                                        <option value="L">
                                                            L
                                                        </option>
                                                        <option value="XL">
                                                            XL
                                                        </option>
                                                        <option value="XXL">
                                                            XXL
                                                        </option>
                                                    </select>
                                                </div>
                                            ) : null}
                                        </>
                                    )}
                                </div>

                                <div className="form-group">
                                    <span style={modalLabelStyle}>
                                        Racepack Pickup
                                    </span>
                                    <span style={modalLabelStyle}>
                                        Racekit di GMS Balikpapan, Jl. Mayjend
                                        Sutoyo Rt.44 No.32
                                    </span>
                                    <span style={modalLabelStyle}>
                                        Penukaran Racekit tanggal 04 Agustus
                                        2024
                                    </span>
                                    <span style={modalLabelStyle}>
                                        Jam penukaran di office hour mulai 10.00
                                        sampai 17.00 WITA
                                    </span>
                                </div>
                            </>
                        ) : null}

                        {/* Form for GMS Run */}
                        {props.selectedEvent.exclusive === "aogfindingmajor" ? (
                            <>
                                <div className="form-group">
                                    <span style={modalLabelStyle}>
                                        Tingkat/Kelas
                                    </span>
                                    <div>
                                        {Object.keys(kelasOptions).map(
                                            (kelasOptionId, i) => (
                                                <Form.Check
                                                    type={"radio"}
                                                    key={i}
                                                    id={`radioFindingMajor-${kelasOptionId}`}
                                                    name={`kelas`}
                                                    value={kelasOptionId}
                                                    label={
                                                        kelasOptions[
                                                            kelasOptionId
                                                        ]
                                                    }
                                                    checked={
                                                        kelas === kelasOptionId
                                                    }
                                                    onChange={() =>
                                                        setKelas(kelasOptionId)
                                                    }
                                                />
                                            )
                                        )}
                                        <Form.Check
                                            type={"radio"}
                                            id={`radioFindingMajor-Other`}
                                            name={`kelas`}
                                            label={
                                                <>
                                                    Lainnya{" "}
                                                    <Form.Control
                                                        ref={txtOther}
                                                        className={
                                                            otherChecked
                                                                ? ""
                                                                : "d-none"
                                                        }
                                                        required={otherChecked}
                                                        type="text"
                                                        value={kelasOther}
                                                        onChange={(e) =>
                                                            setKelasOther(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </>
                                            }
                                            value={"Other"}
                                            checked={otherChecked}
                                            onChange={() => setKelas("Other")}
                                        />
                                    </div>
                                </div>
                            </>
                        ) : null}

                        {/* Form for AOG Bootcamp */}
                        {(props.selectedEvent.exclusive === "aogbootcamp1" || exclusive === "aogbootcamp1regional" || exclusive === "aogbootcamp2" || exclusive === "aogbootcamp2regional") ? (
                            <>
                                {/* jenisPendaftaran Field */}
                                <div className="form-group">
                                    <span style={modalLabelStyle}>
                                        Jenis Pendaftaran
                                    </span>
                                    <div>
                                        {Object.keys(
                                            registrationTypeOptions
                                        ).map((registrationTypeOptionId, i) => (
                                            <Form.Check
                                                type={"radio"}
                                                key={i}
                                                id={`radioRegistrationTypeAOGBootcamp-${registrationTypeOptionId}`}
                                                name={`registrationType`}
                                                value={registrationTypeOptionId}
                                                label={
                                                    registrationTypeOptions[
                                                        registrationTypeOptionId
                                                    ]
                                                }
                                                checked={
                                                    registrationType ===
                                                    registrationTypeOptionId
                                                }
                                                onChange={() => {
                                                    setRegistrationType(
                                                        registrationTypeOptionId
                                                    );

                                                    if (registrationTypeOptionId === 'mandiri') {
                                                        onChangeRegistrantName("");
                                                        onChangeIsCG(false);
                                                        onChangeRegistrantAge("");
                                                        setGender("pria");
                                                    }
                                                }}
                                            />
                                        ))}
                                    </div>
                                </div>
                                {/* Registrant Name */}
                                <hr />
                                {registrationType === 'wali' && (
                                    <div className="form-group">
                                        <span style={modalLabelStyle}>
                                            Nama Lengkap
                                        </span>
                                        <Form.Control
                                            type="text"
                                            placeholder="Masukkan Nama"
                                            value={registrantName}
                                            onChange={(e) => {
                                                onChangeRegistrantName(
                                                    e.target.value
                                                );
                                            }}
                                        />
                                    </div>
                                )}

                                {/* Registrant CG Status*/}
                                {registrationType === 'wali' && (
                                
                                    <div className="form-group">
                                        <Form.Check
                                            type="checkbox"
                                            id="checkbox-isCG"
                                            label="Sudah Bergabung CG"
                                            checked={isCG}
                                            onChange={() => {
                                                onChangeIsCG(!isCG);
                                            }}
                                        />
                                    </div>
                                )}

                                {/* Registrant Age */}
                                {registrationType === 'wali' && (
                                    <div className="form-group">
                                        <span style={modalLabelStyle}>
                                            Usia
                                        </span>
                                        <Form.Control
                                            type="number"
                                            placeholder="Masukkan Usia"
                                            value={registrantAge}
                                            onChange={(e) => {
                                                onChangeRegistrantAge(
                                                    e.target.value
                                                );
                                            }}
                                        />
                                    </div>
                                )}
                                
                                {/* Registrant Gender */}
                                {registrationType === 'wali' && (
                                    <div className="form-group">
                                        <span style={modalLabelStyle}>
                                            Jenis Kelamin
                                        </span>
                                        <div>
                                            {Object.keys(genderOptions).map(
                                                (genderOptionId, i) => (
                                                    <Form.Check
                                                        type={"radio"}
                                                        key={i}
                                                        id={`radioGenderAOGBootcamp-${genderOptionId}`}
                                                        name={`gender`}
                                                        value={genderOptionId}
                                                        label={
                                                            genderOptions[
                                                                genderOptionId
                                                            ]
                                                        }
                                                        checked={
                                                            gender ===
                                                            genderOptionId
                                                        }
                                                        onChange={() => {
                                                            setGender(
                                                                genderOptionId
                                                            );
                                                        }}
                                                    />
                                                )
                                            )}
                                        </div>
                                    </div>
                                )}
                                
                                {/* Registrant Phone Number*/}
                                <div className="form-group">
                                    <span style={modalLabelStyle}>
                                        Nomor Telepon Peserta
                                    </span>
                                    <Form.Control
                                        type="text"
                                        placeholder="Masukkan Nomor Telepon"
                                        value={props.phoneNumber}
                                        onChange={(e) => {
                                            let newValue = e.target.value;

                                            // Your formatting logic
                                            if (newValue.startsWith("+620")) {
                                                newValue = newValue.replace(
                                                    /^(\+620)+/,
                                                    "+62"
                                                );
                                            } else if (
                                                newValue.startsWith("+62")
                                            ) {
                                                newValue = newValue.replace(
                                                    /^(\+62)+/,
                                                    ""
                                                );
                                            } else if (
                                                newValue.startsWith("+6")
                                            ) {
                                                newValue = newValue.replace(
                                                    /^(\+6)+/,
                                                    ""
                                                );
                                            } else if (
                                                newValue.startsWith("0")
                                            ) {
                                                newValue = newValue.replace(
                                                    /^(0)+/,
                                                    ""
                                                );
                                            }

                                            if (/^\d{0,12}$/.test(newValue)) {
                                                onChangePhoneNumber(
                                                    "+62" + newValue
                                                );
                                            }
                                        }}
                                    />
                                </div>

                                {/* Registrant Medical Condition */}
                                <div className="form-group">
                                    <span style={modalLabelStyle}>
                                        Alergi dan Riwayat Penyakit
                                    </span>
                                    <Form.Control
                                        as="textarea"
                                        placeholder="Masukkan Alergi dan Riwayat Penyakit"
                                        value={props.medicalCondition}
                                        onChange={(e) => {
                                            onChangeMedicalCondition(
                                                e.target.value
                                            );
                                        }}
                                    />
                                </div>
                            </>
                        ) : null}

                        {props.selectedEvent.exclusive ===
                        "christmascelebrationforvip" ? (
                            <>
                                <div className="form-group">
                                    <span style={modalLabelStyle}>
                                        Catatan *
                                    </span>
                                    <Form.Control
                                        type="text"
                                        placeholder="Masukkan catatan nama VIP"
                                        value={catatan}
                                        onChange={(e) => {
                                            onChangeCatatan(e.target.value);
                                        }}
                                    />
                                </div>
                            </>
                        ) : null}

                        {/* Service Number of Ticket Button */}
                        <div className="form-group">
                            <span style={modalLabelStyle}>
                                {l10n["numbersOfTicket"][language]}
                            </span>
                            <span>
                                <input
                                    type="hidden"
                                    name="ticket"
                                    value={props.ticket}
                                    onChange={props.onChangeTicket}
                                />
                                {Number(props.maxTicketOrder) <= 5 ? (
                                    <props.ticketButton />
                                ) : (
                                    <div className="form-group">
                                        <InputGroup className="mb-1">
                                            <Button
                                                variant="primary"
                                                onClick={decreaseTicketQty}
                                                className="mr-3"
                                            >
                                                -
                                            </Button>
                                            <Form.Control
                                                type="number"
                                                className="text-center"
                                                value={ticketQty}
                                                onChange={(e) =>
                                                    setTicketQty(e.target.value)
                                                }
                                                onBlur={onInputTicketBlur}
                                            />
                                            <Button
                                                variant="primary"
                                                onClick={increaseTicketQty}
                                                className="ml-3"
                                            >
                                                +
                                            </Button>
                                        </InputGroup>

                                        <span style={modalLabelStyle}>
                                            {l10n["infoMaxTicket"][language]}{" "}
                                            <b>{props.maxTicketOrder}</b>{" "}
                                            {l10n["ticket"][language]}
                                        </span>
                                    </div>
                                )}
                            </span>
                        </div>

                        {/* Overflow Agreement -- hide at GMS Run */}
                        <div style={{ display: "none" }}>
                            <div className="row d-flex align-items-center">
                                <div className="col-2">
                                    <input
                                        type="checkbox"
                                        name="agreement"
                                        value={agreeOverflow}
                                        checked={agreeOverflow}
                                        onChange={() =>
                                            setAgreeOverflow(!agreeOverflow)
                                        }
                                        style={agreementCheckStyle}
                                    />
                                </div>
                                <div
                                    className="col-10"
                                    onClick={() =>
                                        setAgreeOverflow(!agreeOverflow)
                                    }
                                    value={agreeOverflow}
                                >
                                    {l10n["overflowAgree"][language]}
                                </div>
                            </div>
                        </div>
                        {/* Service Price */}
                        {props.selectedEvent.is_paid === 1 ? (
                            <div className="form-group">
                                <span style={modalLabelStyle}>
                                    {l10n["price"][language]}
                                </span>
                                <span style={modalValuePriceStyle}>
                                    {props.selectedEventSub.price
                                        ? "IDR " +
                                          (
                                              props.selectedEventSub.price *
                                              props.ticket
                                          ).toLocaleString()
                                        : 0}
                                </span>
                            </div>
                        ) : null}
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={props.handleClose}>
                            {l10n["cancel"][language]}
                        </Button>
                        <input
                            disabled={
                                props.selectedEventSub.quantity <= 0 ||
                                aogFindingMajorCheck ||
                                aogBootcampCheck ||
                                christmasVipCheck ||
                                vmCheck /* || agreeOverflow === false*/
                            }
                            style={modalSubmitStyle}
                            type="submit"
                            value={l10n["order"][language]}
                            className="btn btn-primary"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}

// Styles
const modalTitleStyle = {
    color: "#555",
    fontSize: "0.9rem",
    fontWeight: "bold",
};

const modalSubmitStyle = {
    // textTransform: 'uppercase',
};

const agreementCheckStyle = {
    width: "24px",
    height: "24px",
    cursor: "pointer",
};

const modalLabelStyle = {
    fontSize: "0.75rem",
    color: "#888",
    marginBottom: "2px",
    display: "block",
    fontWeight: "400",
};

const modalValueStyle = {
    fontSize: "1.05rem",
    color: "#555",
    marginBottom: "12px",
    display: "block",
    fontWeight: "bold",
};
const infoStyle = {
    color: "#423f40",
    fontSize: "0.85rem",
};
const titleStyle = {
    fontSize: "1rem",
    fontWeight: "bold",
    marginBottom: "10px",
};
const modalValuePriceStyle = {
    fontSize: "1.05rem",
    color: "#dc3545",
    marginBottom: "12px",
    display: "block",
    fontWeight: "bold",
};
const selectStyle = {
    fontSize: "1.2rem",
    width: "100%",
    padding: "4px",
};
const buttonChartSizeStyle = {
    color: "#423f40",
    fontSize: "0.9rem",
    fontWeight: "bold",
    marginBottom: "4px",
};
